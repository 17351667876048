<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas</span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DE LAS CALIFICACIONES
                        </span>
                        <hr>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="12" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listCalificaciones.length}}</span>
                                <br>
                                <span class="text-muted">Calificaciones Registradas</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Lista de Calificaciones</span>
                        <b-button v-if="listCalificaciones.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listCalificaciones" :fields="camposCalificacion" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(nroDoc)="data">
                                        {{data.item.nro_document | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(nombre)="data">
                                        {{data.item.nombre_user | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(comentario)="data">
                                        {{data.item.comentario | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(fecha)="data">
                                        <!-- {{data.item.fechaForm | truncate(30)}} -->
                                        {{formatearFecha(data.item.fechaForm,'DD-MM-YYYY')}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalCalificaciones(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCalificacion(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>

        <CModal :show.sync="modalActCalificacion" size="xl" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Mostrar Calificación</span>
                </h6>
                <CButtonClose @click="modalActCalificacion = false" class="text-white" />
            </template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit()">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="numero de documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Número de Documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Número de Documento" v-model.trim="datosActCalificacion.nro_document"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre" v-model.trim="datosActCalificacion.nombre_user"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosActCalificacion.fechaForm"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="hora" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Hora:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la Hora" v-model.trim="datosActCalificacion.horaFormat"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <b-table-simple bordered hover show-empty mediun responsive outlined>
                                <b-thead>
                                    <b-tr class="text-center">
                                        <b-th>Preguntas</b-th>
                                        <b-th>Respuestas</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr class="text-center">
                                        <b-td width="50%">
                                            {{datosActCalificacion.pregunta1}}
                                        </b-td>
                                        <b-td width="50%">
                                            <!-- <i class="far fa-star"></i> -->
                                            <!-- <i v-if="datosActCalificacion.respuesta1 == 1" class="fas fa-star"></i>
                                            <i v-if="datosActCalificacion.respuesta1 == 1" class="fas fa-star"></i>
                                            <br> -->
                                            {{datosActCalificacion.respuesta1 == 1 ? 'Malo'  : ''}}
                                            {{datosActCalificacion.respuesta1 == 2 ? 'Aburrido'  : ''}}
                                            {{datosActCalificacion.respuesta1 == 3 ? 'Bien'  : ''}}
                                            {{datosActCalificacion.respuesta1 == 4 ? 'Bueno'  : ''}}
                                            {{datosActCalificacion.respuesta1 == 5 ? 'Excelente'  : ''}}
                                        </b-td>
                                    </b-tr>
                                    <b-tr class="text-center">
                                        <b-td width="50%">
                                            {{datosActCalificacion.pregunta2}}
                                        </b-td>
                                        <b-td width="50%">
                                            {{datosActCalificacion.respuesta2 == 1 ? 'Malo'  : ''}}
                                            {{datosActCalificacion.respuesta2 == 2 ? 'Aburrido'  : ''}}
                                            {{datosActCalificacion.respuesta2 == 3 ? 'Bien'  : ''}}
                                            {{datosActCalificacion.respuesta2 == 4 ? 'Bueno'  : ''}}
                                            {{datosActCalificacion.respuesta2 == 5 ? 'Excelente'  : ''}}
                                        </b-td>
                                    </b-tr>
                                    <b-tr class="text-center">
                                        <b-td width="50%">
                                            {{datosActCalificacion.pregunta3}}
                                        </b-td>
                                        <b-td width="50%">
                                            {{datosActCalificacion.respuesta3 == 1 ? 'Malo'  : ''}}
                                            {{datosActCalificacion.respuesta3 == 2 ? 'Aburrido'  : ''}}
                                            {{datosActCalificacion.respuesta3 == 3 ? 'Bien'  : ''}}
                                            {{datosActCalificacion.respuesta3 == 4 ? 'Bueno'  : ''}}
                                            {{datosActCalificacion.respuesta3 == 5 ? 'Excelente'  : ''}}
                                        </b-td>
                                    </b-tr>
                                    <b-tr class="text-center">
                                        <b-td width="50%">
                                            {{datosActCalificacion.pregunta4}}
                                        </b-td>
                                        <b-td width="50%">
                                            {{datosActCalificacion.respuesta4 == 1 ? 'Malo'  : ''}}
                                            {{datosActCalificacion.respuesta4 == 2 ? 'Aburrido'  : ''}}
                                            {{datosActCalificacion.respuesta4 == 3 ? 'Bien'  : ''}}
                                            {{datosActCalificacion.respuesta4 == 4 ? 'Bueno'  : ''}}
                                            {{datosActCalificacion.respuesta4 == 5 ? 'Excelente'  : ''}}
                                        </b-td>
                                    </b-tr>
                                    <b-tr class="text-center">
                                        <b-td width="50%">
                                            {{datosActCalificacion.pregunta5}}
                                        </b-td>
                                        <b-td width="50%">
                                            {{datosActCalificacion.respuesta5 == 1 ? 'Malo'  : ''}}
                                            {{datosActCalificacion.respuesta5 == 2 ? 'Aburrido'  : ''}}
                                            {{datosActCalificacion.respuesta5 == 3 ? 'Bien'  : ''}}
                                            {{datosActCalificacion.respuesta5 == 4 ? 'Bueno'  : ''}}
                                            {{datosActCalificacion.respuesta5 == 5 ? 'Excelente'  : ''}}
                                        </b-td>
                                    </b-tr>
                                    <b-tr class="text-center">
                                        <b-td width="50%">
                                            {{datosActCalificacion.pregunta6}}
                                        </b-td>
                                        <b-td width="50%">
                                            {{datosActCalificacion.respuesta6 == 1 ? 'Malo'  : ''}}
                                            {{datosActCalificacion.respuesta6 == 2 ? 'Aburrido'  : ''}}
                                            {{datosActCalificacion.respuesta6 == 3 ? 'Bien'  : ''}}
                                            {{datosActCalificacion.respuesta6 == 4 ? 'Bueno'  : ''}}
                                            {{datosActCalificacion.respuesta6 == 5 ? 'Excelente'  : ''}}
                                        </b-td>
                                    </b-tr>
                                    <b-tr class="text-center">
                                        <b-td width="50%">
                                            {{datosActCalificacion.pregunta7}}
                                        </b-td>
                                        <b-td width="50%">
                                            {{datosActCalificacion.respuesta7 == 1 ? 'Malo'  : ''}}
                                            {{datosActCalificacion.respuesta7 == 2 ? 'Aburrido'  : ''}}
                                            {{datosActCalificacion.respuesta7 == 3 ? 'Bien'  : ''}}
                                            {{datosActCalificacion.respuesta7 == 4 ? 'Bueno'  : ''}}
                                            {{datosActCalificacion.respuesta7 == 5 ? 'Excelente'  : ''}}
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="comentario" v-slot="validationContext">
                                <b-form-group label="Comentario:" class="mb-2">
                                    <b-form-textarea v-model="datosActCalificacion.comentario" :state="getValidationState(validationContext)" placeholder="Ingrese el Comentario" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>
    </div>
</template>

<script>
import firebase from 'firebase';
import XLSX from 'sheetjs-style-v2';
const dbCalificaciones = firebase.firestore().collection('calificaciones');

export default {
    data() {
        return {
            listCalificaciones: [],

            camposCalificacion: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nroDoc",
                    label: "Nro. Documento",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "comentario",
                    label: "Comentario",
                    class: "text-center",
                },
                {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            modalActCalificacion: false,

            datosActCalificacion: {
                id: '',
                horaFormat: '',
                fechaForm: moment().format('YYYY-MM-DD'),
                nro_document: '',
                nombre_user: '',
                pregunta1: '',
                pregunta2: '',
                pregunta3: '',
                pregunta4: '',
                pregunta5: '',
                pregunta6: '',
                pregunta7: '',
                respuesta1: '',
                respuesta2: '',
                respuesta3: '',
                respuesta4: '',
                respuesta5: '',
                respuesta6: '',
                respuesta7: '',
                comentario: '',
            }
        }
    },
    methods: {
        exportDataToExcel(){
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };

            const valDate = date => {
                if (date && date !== undefined) {
                    return moment(date).format('DD-MM-YYYY')
                }
                return '';
            };

            let dataExcel = this.listCalificaciones.map(data => {
                return {
                    A: valUpper(data['nro_document']),
                    B: valUpper(data['nombre_user']),
                    C: valDate(data['fechaForm']),
                    D: valUpper(data['horaFormat']),
                    E: valUpper(data['pregunta1']),
                    F: data['respuesta1'] == 1 ? 'Malo' : data['respuesta1'] == 2 ? 'Aburrido' : data['respuesta1'] == 3 ? 'Bien' : data['respuesta1'] == 4 ? 'Bueno' : data['respuesta1'] == 5 ? 'Excelente' : '',
                    G: valUpper(data['pregunta2']),
                    H: data['respuesta2'] == 1 ? 'Malo' : data['respuesta2'] == 2 ? 'Aburrido' : data['respuesta2'] == 3 ? 'Bien' : data['respuesta2'] == 4 ? 'Bueno' : data['respuesta2'] == 5 ? 'Excelente' : '',
                    I: valUpper(data['pregunta3']),
                    J: data['respuesta3'] == 1 ? 'Malo' : data['respuesta3'] == 2 ? 'Aburrido' : data['respuesta3'] == 3 ? 'Bien' : data['respuesta3'] == 4 ? 'Bueno' : data['respuesta3'] == 5 ? 'Excelente' : '',
                    K: valUpper(data['pregunta4']),
                    L: data['respuesta4'] == 1 ? 'Malo' : data['respuesta4'] == 2 ? 'Aburrido' : data['respuesta4'] == 3 ? 'Bien' : data['respuesta4'] == 4 ? 'Bueno' : data['respuesta4'] == 5 ? 'Excelente' : '',
                    M: valUpper(data['pregunta5']),
                    N: data['respuesta5'] == 1 ? 'Malo' : data['respuesta5'] == 2 ? 'Aburrido' : data['respuesta5'] == 3 ? 'Bien' : data['respuesta5'] == 4 ? 'Bueno' : data['respuesta5'] == 5 ? 'Excelente' : '',
                    O: valUpper(data['pregunta6']),
                    P: data['respuesta6'] == 1 ? 'Malo' : data['respuesta6'] == 2 ? 'Aburrido' : data['respuesta6'] == 3 ? 'Bien' : data['respuesta6'] == 4 ? 'Bueno' : data['respuesta6'] == 5 ? 'Excelente' : '',
                    Q: valUpper(data['pregunta7']),
                    R: data['respuesta7'] == 1 ? 'Malo' : data['respuesta7'] == 2 ? 'Aburrido' : data['respuesta7'] == 3 ? 'Bien' : data['respuesta7'] == 4 ? 'Bueno' : data['respuesta7'] == 5 ? 'Excelente' : '',
                };
            });

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [{
                s: {
                    r: 1,
                    c: 1,
                },
                e: {
                    r: 1,
                    c: 3,
                },
            }];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 30, //A
                },
                {
                    wch: 40, //B
                },
                {
                    wch: 15, //C
                },
                {
                    wch: 15, //D
                },
                {
                    wch: 50, //E
                },
                {
                    wch: 15, //F
                },
                {
                    wch: 50, //G
                },
                {
                    wch: 15, //H
                },
                {
                    wch: 50, //I
                },
                {
                    wch: 15, //J
                },
                {
                    wch: 50, //K
                },
                {
                    wch: 15, //L
                },
                {
                    wch: 50, //M
                },
                {
                    wch: 15, //N
                },
                {
                    wch: 50, //O
                },
                {
                    wch: 15, //P
                },
                {
                    wch: 50, //Q
                },
                {
                    wch: 15, //R
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for(let i of [
                {
                    f: 'B2',
                    v: 'CALIFICACIONES',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'NUMERO DE DOCUMENTO',
                },
                {
                    f: 'B4',
                    v: 'NOMBRE',
                },
                {
                    f: 'C4',
                    v: 'FECHA',
                },
                {
                    f: 'D4',
                    v: 'HORA',
                },
                {
                    f: 'E4',
                    v: 'PREGUNTA 1',
                },
                {
                    f: 'F4',
                    v: 'RESPUESTA 1',
                },
                {
                    f: 'G4',
                    v: 'PREGUNTA 2',
                },
                {
                    f: 'H4',
                    v: 'RESPUESTA 2',
                },
                {
                    f: 'I4',
                    v: 'PREGUNTA 3',
                },
                {
                    f: 'J4',
                    v: 'RESPUESTA 3',
                },
                {
                    f: 'K4',
                    v: 'PREGUNTA 4',
                },
                {
                    f: 'L4',
                    v: 'RESPUESTA 4',
                },
                {
                    f: 'M4',
                    v: 'PREGUNTA 5',
                },
                {
                    f: 'N4',
                    v: 'RESPUESTA 5',
                },
                {
                    f: 'O4',
                    v: 'PREGUNTA 6',
                },
                {
                    f: 'P4',
                    v: 'RESPUESTA 6',
                },
                {
                    f: 'Q4',
                    v: 'PREGUNTA 7',
                },
                {
                    f: 'R4',
                    v: 'RESPUESTA 7',
                },
            ]){
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'CALIFICACIONES');
            XLSX.writeFile(wb, `CALIFICACIONES ${now}.xlsx`);
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length;
            this.paginaActual = 1;
        },
        formatearFecha(fecha,formato){
            return moment(fecha).format(formato);
        },
        listarCalificaciones() {
            let me = this;
            dbCalificaciones
            .where("estado","==", 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot) => {
                me.listCalificaciones = [];
                querySnapshot.forEach((doc) => {
                    me.listCalificaciones.push({
                        id: doc.id,
                        nombre_user: doc.data().nombre_user,
                        nro_document: doc.data().nro_document,
                        comentario: doc.data().comentario,
                        fechaRegistro_UTC: doc.data().fechaRegistro_UTC,
                        pregunta1: doc.data().pregunta1,
                        pregunta2: doc.data().pregunta2,
                        pregunta3: doc.data().pregunta3,
                        pregunta4: doc.data().pregunta4,
                        pregunta5: doc.data().pregunta5,
                        pregunta6: doc.data().pregunta6,
                        pregunta7: doc.data().pregunta7,
                        respuesta1: doc.data().respuesta1,
                        respuesta2: doc.data().respuesta2,
                        respuesta3: doc.data().respuesta3,
                        respuesta4: doc.data().respuesta4,
                        respuesta5: doc.data().respuesta5,
                        respuesta6: doc.data().respuesta6,
                        respuesta7: doc.data().respuesta7,
                        horaFormat: doc.data().horaFormat,
                        fechaForm: doc.data().fechaForm,
                    });
                });
                 me.listCalificaciones.sort((a,b) => (a.fechaRegistro_UTC > b.fechaRegistro_UTC) ? -1 : ((b.fechaRegistro_UTC > a.fechaRegistro_UTC) ? 1 : 0));
            });
        },
        eliminarCalificacion(param) {
            let me = this;

            me.$swal.fire({
                title: '¿Estas seguro de eliminar la Calificación?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbCalificaciones.doc(param.item.id).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                        // me.disabled = false;
                    })
                    .catch((error) => {
                        // me.disabled = false;
                        me.swat('error', 'Algo salió mal!')
                    });

                }
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        abrirModalCalificaciones(param) {
            let me = this;
            
            me.datosActCalificacion.id = param.item.id;
            me.datosActCalificacion.horaFormat = param.item.horaFormat;
            me.datosActCalificacion.fechaForm = param.item.fechaForm;
            me.datosActCalificacion.nro_document = param.item.nro_document;
            me.datosActCalificacion.nombre_user = param.item.nombre_user;
            me.datosActCalificacion.pregunta1 = param.item.pregunta1;
            me.datosActCalificacion.pregunta2 = param.item.pregunta2;
            me.datosActCalificacion.pregunta3 = param.item.pregunta3;
            me.datosActCalificacion.pregunta4 = param.item.pregunta4;
            me.datosActCalificacion.pregunta5 = param.item.pregunta5;
            me.datosActCalificacion.pregunta6 = param.item.pregunta6;
            me.datosActCalificacion.pregunta7 = param.item.pregunta7;
            me.datosActCalificacion.respuesta1 = param.item.respuesta1;
            me.datosActCalificacion.respuesta2 = param.item.respuesta2;
            me.datosActCalificacion.respuesta3 = param.item.respuesta3;
            me.datosActCalificacion.respuesta4 = param.item.respuesta4;
            me.datosActCalificacion.respuesta5 = param.item.respuesta5;
            me.datosActCalificacion.respuesta6 = param.item.respuesta6;
            me.datosActCalificacion.respuesta7 = param.item.respuesta7;
            me.datosActCalificacion.comentario = param.item.comentario;

            me.modalActCalificacion = true;
        },
        resetModalCalificacion() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

            this.datosActCalificacion.id = '';
            this.datosActCalificacion.horaFormat = '';
            this.datosActCalificacion.fechaForm = moment().format('YYYY-MM-DD');
            this.datosActCalificacion.nro_document = '';
            this.datosActCalificacion.nombre_user = '';
            this.datosActCalificacion.pregunta1 = '';
            this.datosActCalificacion.pregunta2 = '';
            this.datosActCalificacion.pregunta3 = '';
            this.datosActCalificacion.pregunta4 = '';
            this.datosActCalificacion.pregunta5 = '';
            this.datosActCalificacion.pregunta6 = '';
            this.datosActCalificacion.pregunta7 = '';
            this.datosActCalificacion.respuesta1 = '';
            this.datosActCalificacion.respuesta2 = '';
            this.datosActCalificacion.respuesta3 = '';
            this.datosActCalificacion.respuesta4 = '';
            this.datosActCalificacion.respuesta5 = '';
            this.datosActCalificacion.respuesta6 = '';
            this.datosActCalificacion.respuesta7 = '';
            this.datosActCalificacion.comentario = '';
        },
    },
    computed: {

    },
    watch: {
        modalActCalificacion: function (val) {
            if (val == false) {
                this.resetModalCalificacion();
            }
        },
    },
    mounted(){
        if (this.$store.state.user) {
            this.listarCalificaciones();
        }
    }
}
</script>